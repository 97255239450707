// Generated by Framer (38f2e7f)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Clipboard from "https://framerusercontent.com/modules/Hj20QU19p80mpYsvesiZ/RfHh9MIwqlgi04HKZ3Qo/Clipboard.js";
import * as localizedValues from "./s7o0j8KHc-0.js";
const ClipboardFonts = getFonts(Clipboard);

const enabledGestures = {BbrT20n4J: {hover: true}};

const serializationHash = "framer-kb3Ng"

const variantClassNames = {BbrT20n4J: "framer-v-tgmqfs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {D4PSPoXBX: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "BbrT20n4J", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "k7kRjRFd8"}}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-tgmqfs", className, classNames)} framer-1nfqdw1`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BbrT20n4J"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"BbrT20n4J-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1051rdm-container"} layoutDependency={layoutDependency} layoutId={"FxOyjnLuy-container"} transformTemplate={transformTemplate1}><Clipboard borderRadius={50} bottomLeftRadius={50} bottomRightRadius={50} color={"rgb(255, 82, 14)"} content={""} fill={"var(--token-08edd575-3b24-4b25-b0aa-03dd0fc5e8fe, rgb(255, 255, 255))"} font={{}} height={"100%"} id={"FxOyjnLuy"} isMixedBorderRadius={false} label={getLocalizedValue("v0", activeLocale) ?? "Schedule a Consultation"} layoutId={"FxOyjnLuy"} padding={15} paddingBottom={8} paddingLeft={15} paddingPerSide paddingRight={15} paddingTop={8} topLeftRadius={50} topRightRadius={50} width={"100%"} {...addPropertyOverrides({"BbrT20n4J-hover": {fill: "var(--token-e94fa790-b61f-4d86-a1d0-3ac4e6d6f183, rgb(119, 119, 119))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kb3Ng.framer-1nfqdw1, .framer-kb3Ng .framer-1nfqdw1 { display: block; }", ".framer-kb3Ng.framer-tgmqfs { cursor: pointer; height: 35px; overflow: visible; position: relative; text-decoration: none; width: 162px; }", ".framer-kb3Ng .framer-1051rdm-container { flex: none; height: auto; left: 50%; position: absolute; top: 49%; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 162
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"y9oAXqgwK":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framers7o0j8KHc: React.ComponentType<Props> = withCSS(Component, css, "framer-kb3Ng") as typeof Component;
export default Framers7o0j8KHc;

Framers7o0j8KHc.displayName = "Clipboard card";

Framers7o0j8KHc.defaultProps = {height: 35, width: 162};

addFonts(Framers7o0j8KHc, [{explicitInter: true, fonts: []}, ...ClipboardFonts], {supportsExplicitInterCodegen: true})